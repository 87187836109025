import React from "react";

import img_sokoban_extra_pc8801_title from "../../../assets/screenshots/sokoban_extra_pc8801_title.png";
import img_sokoban_extra_pc8801_001 from "../../../assets/screenshots/sokoban_extra_pc8801_001.png";

export default function ArticleSokobanExtraEditionPc8801() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 rounded bg-white">
                            <h3 className="title mb-0">Sokoban Extra Edition (PC-8801)</h3>
                            <p className="text-right">
                                <sub>Published: 2022 May 22</sub>
                            </p>
                            <p>
                                An additional Sokoban game for PC-8801 was published in the
                                Japanese magazine, "PCマガジン", in the August 1983 issue (pages
                                52–56).
                            </p>
                            <p>
                                This came with 10 levels that had not been published before.
                            </p>
                            <p>
                                <img
                                    className={`img-fluid`}
                                    src={img_sokoban_extra_pc8801_title}
                                    alt="Sokoban Extra (PC-8801) title"
                                />
                            </p>
                            <p>
                                <img
                                    className={`img-fluid`}
                                    src={img_sokoban_extra_pc8801_001}
                                    alt="Sokoban Extra (PC-8801) level 1"
                                />
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
