import React from "react";

import img_sokoban_pc8801_popcom_1984_issue_august_title
    from "../../../assets/screenshots/sokoban_pc8801_popcom_1984_issue_august_title.png";
import img_sokoban_pc8801_popcom_1984_issue_august_001
    from "../../../assets/screenshots/sokoban_pc8801_popcom_1984_issue_august_001.png";

export default function ArticleSokobanPc8801PortFromMulti8() {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 rounded bg-white">
                            <div className="row mb-4">
                                <div className="col-12 rounded bg-white">
                                    <h3 className="title mb-0">
                                        Sokoban 1 (PC-8801 Port from Multi-8)
                                    </h3>
                                    <p className="text-right">
                                        <sub>Published: 2024 October 27</sub>
                                    </p>
                                    <p>
                                        The August 1984 issue of “POPCOM” published the program lists for Sokoban for
                                        the Mitsubishi Multi-8 and its ports for the FM-7, Sharp X1, PC-8801, PC-9801,
                                        Mitsubishi S1, and PASOPIA 7.
                                    </p>
                                    <p>
                                        The PC-8801 port differs slightly from the commercial tape version: the main
                                        menu omits player sprites, and during gameplay, the warehouse number is
                                        displayed at the top of the screen. A sound effect plays each time the player
                                        walks. While the Multi-8 version plays computer-generated music upon puzzle
                                        completion, this feature is absent in the PC-8801 port. However, my friend Taka
                                        found a way to incorporate the original Multi-8 music code into the PC-8801
                                        port, making it compatible only with the PC-8801mkIISR or higher.
                                    </p>
                                    <p>
                                        <img
                                            className={`img-fluid`}
                                            src={img_sokoban_pc8801_popcom_1984_issue_august_title}
                                            alt="Sokoban 1 (PC-8801) (POPCOM 1984 issue 8 version) main menu"
                                        />
                                    </p>
                                    <p>
                                        <img
                                            className={`img-fluid`}
                                            src={img_sokoban_pc8801_popcom_1984_issue_august_001}
                                            alt="Sokoban 1 (PC-8801) (POPCOM 1984 issue 8 version) level 1"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
