import {InterfaceGameItem} from "../interfaces/interface-game-item";
import {Falcon, Itochu, ThinkingRabbit, Unbalance} from "./publishers.data";

import img_sokoban_pc8801_001 from "../../assets/screenshots/sokoban_pc8801_001.png";
import img_sokoban_pc8801_title from "../../assets/screenshots/sokoban_pc8801_title.png";

import img_sokoban_pc8001_mk2_001 from "../../assets/screenshots/sokoban_pc8001_mk2_001.png";
import img_sokoban_pc8001_mk2_title from "../../assets/screenshots/sokoban_pc8001_mk2_title.png";

import img_sokoban_pc6001_32k_001 from "../../assets/screenshots/sokoban_pc6001_32k_001.png";
import img_sokoban_pc6001_32k_title from "../../assets/screenshots/sokoban_pc6001_32k_title.png";

import img_sokoban_pc6001_mk2_001 from "../../assets/screenshots/sokoban_pc6001_mk2_001.png";
import img_sokoban_pc6001_mk2_title from "../../assets/screenshots/sokoban_pc6001_mk2_title.png";

import img_sokoban_mz2000_mono_001 from "../../assets/screenshots/sokoban_mz2000_mono_001.png";
import img_sokoban_mz2000_mono_title from "../../assets/screenshots/sokoban_mz2000_mono_title.png";

import img_sokoban_mz2000_color_001 from "../../assets/screenshots/sokoban_mz2000_color_001.png";
import img_sokoban_mz2000_color_title from "../../assets/screenshots/sokoban_mz2000_color_title.png";

import img_sokoban_sharp_x1_001 from "../../assets/screenshots/sokoban_sharp_x1_001.png";
import img_sokoban_sharp_x1_title from "../../assets/screenshots/sokoban_sharp_x1_title.png";

import img_sokoban_extra_pc8801_001 from "../../assets/screenshots/sokoban_extra_pc8801_001.png";
import img_sokoban_extra_pc8801_title from "../../assets/screenshots/sokoban_extra_pc8801_title.png";

import img_sokoban_extra_pc6001_32k_001 from "../../assets/screenshots/sokoban_extra_pc6001_32k_001.png";
import img_sokoban_extra_pc6001_32k_title from "../../assets/screenshots/sokoban_extra_pc6001_32k_title.png";

import img_sokoban_tiny_editor_mz700_001 from "../../assets/screenshots/sokoban_tiny_editor_mz700_001.png";
import img_sokoban_tiny_editor_mz700_title from "../../assets/screenshots/sokoban_tiny_editor_mz700_title.png";

import img_sokoban_tiny_editor_pc8001_001 from "../../assets/screenshots/sokoban_tiny_editor_pc8001_001.png";
import img_sokoban_tiny_editor_pc8001_title from "../../assets/screenshots/sokoban_tiny_editor_pc8001_title.png";

import img_sokoban2_pc8001_mk2_001 from "../../assets/screenshots/sokoban2_pc8001_mk2_001.png";
import img_sokoban2_pc8001_mk2_title from "../../assets/screenshots/sokoban2_pc8001_mk2_title.png";

import img_sokoban2_pc6001_32k_001 from "../../assets/screenshots/sokoban2_pc6001_32k_001.png";
import img_sokoban2_pc6001_32k_title_a from "../../assets/screenshots/sokoban2_pc6001_32k_title_a.png";
import img_sokoban2_pc6001_32k_title_b from "../../assets/screenshots/sokoban2_pc6001_32k_title_b.png";

import img_sokoban2_pc6001_mk2_001 from "../../assets/screenshots/sokoban2_pc6001_mk2_001.png";
import img_sokoban2_pc6001_mk2_title_a from "../../assets/screenshots/sokoban2_pc6001_mk2_title_a.png";
import img_sokoban2_pc6001_mk2_title_b from "../../assets/screenshots/sokoban2_pc6001_mk2_title_b.png";

import img_sokoban2_pc8801_001 from "../../assets/screenshots/sokoban2_pc8801_001.png";
import img_sokoban2_pc8801_title from "../../assets/screenshots/sokoban2_pc8801_title.png";

import img_sokoban2_sharp_x1_001 from "../../assets/screenshots/sokoban2_sharp_x1_001.png";
import img_sokoban2_sharp_x1_title from "../../assets/screenshots/sokoban2_sharp_x1_title.png";

import img_sokoban_msx_rom_001 from "../../assets/screenshots/sokoban_msx_rom_001.png";
import img_sokoban_msx_rom_title from "../../assets/screenshots/sokoban_msx_rom_title.png";

import img_sokoban_msx_tape_001 from "../../assets/screenshots/sokoban_msx_tape_001.png";
import img_sokoban_msx_tape_title from "../../assets/screenshots/sokoban_msx_tape_title.png";

import img_sokoban_sega_sg1000ii_001 from "../../assets/screenshots/sokoban_sega_sg1000ii_001.png";
import img_sokoban_sega_sg1000ii_title from "../../assets/screenshots/sokoban_sega_sg1000ii_title.png";

import img_sokoban_smc77_001 from "../../assets/screenshots/sokoban_smc77_001.png";
import img_sokoban_smc77_title from "../../assets/screenshots/sokoban_smc77_title.png";

import img_sokoban_epoch_001 from "../../assets/screenshots/sokoban_epoch_001.png";
import img_sokoban_epoch_title from "../../assets/screenshots/sokoban_epoch_title.png";

import img_namida_no_sokoban_special_famicom_001
  from "../../assets/screenshots/namida_no_sokoban_special_famicom_001.png";
import img_namida_no_sokoban_special_famicom_001big
  from "../../assets/screenshots/namida_no_sokoban_special_famicom_001big.png";
import img_namida_no_sokoban_special_famicom_title
  from "../../assets/screenshots/namida_no_sokoban_special_famicom_title.png";

import img_sokoban_for_pasopia7_001 from "../../assets/screenshots/sokoban_for_pasopia7_001.png";
import img_sokoban_for_pasopia7_menu_play from "../../assets/screenshots/sokoban_for_pasopia7_menu_play.png";
import img_sokoban_for_pasopia7_menu from "../../assets/screenshots/sokoban_for_pasopia7_menu.png";
import img_sokoban_for_pasopia7_title from "../../assets/screenshots/sokoban_for_pasopia7_title.png";

import img_sokoban_perfect_msx2_001 from "../../assets/screenshots/sokoban_perfect_msx2_001.png";
import img_sokoban_perfect_msx2_title from "../../assets/screenshots/sokoban_perfect_msx2_title.png";

import img_sokoban_perfect_sharp_x68000_001 from "../../assets/screenshots/sokoban_perfect_sharp_x68000_001.png";
import img_sokoban_perfect_sharp_x68000_title from "../../assets/screenshots/sokoban_perfect_sharp_x68000_title.png";

import img_sokoban_perfect_pc98_001 from "../../assets/screenshots/sokoban_perfect_pc98_001.png";
import img_sokoban_perfect_pc98_title from "../../assets/screenshots/sokoban_perfect_pc98_title.png";

import img_sokoban_revenge_pc98_001 from "../../assets/screenshots/sokoban_revenge_pc98_001.png";
import img_sokoban_revenge_pc98_title from "../../assets/screenshots/sokoban_revenge_pc98_title.png";

import img_sokoban_select30_pc98_001 from "../../assets/screenshots/sokoban_select30_pc98_001.png";
import img_sokoban_select30_pc98_title from "../../assets/screenshots/sokoban_select30_pc98_title.png";

import img_sokoban_revenge_j3100_001 from "../../assets/screenshots/sokoban_revenge_j3100_001.png";
import img_sokoban_revenge_j3100_title from "../../assets/screenshots/sokoban_revenge_j3100_title.png";

import img_sokoban_revenge_sx_68k_sharp_x68000_001
  from "../../assets/screenshots/sokoban_revenge_sx_68k_sharp_x68000_001.png";
import img_sokoban_revenge_sx_68k_sharp_x68000_title
  from "../../assets/screenshots/sokoban_revenge_sx_68k_sharp_x68000_title.png";

import img_sokoban_perfect_fm_towns_001 from "../../assets/screenshots/sokoban_perfect_fm_towns_001.png";
import img_sokoban_perfect_fm_towns_title from "../../assets/screenshots/sokoban_perfect_fm_towns_title.png";

import img_sokoban_gameboy_001 from "../../assets/screenshots/sokoban_gameboy_001.png";
import img_sokoban_gameboy_title from "../../assets/screenshots/sokoban_gameboy_title.png";

import img_sokoban2_gameboy_001 from "../../assets/screenshots/sokoban2_gameboy_001.png";
import img_sokoban2_gameboy_title from "../../assets/screenshots/sokoban2_gameboy_title.png";

import img_sokoban_deluxe_namco_system1_001 from "../../assets/screenshots/sokoban_deluxe_namco_system1_001.png";
import img_sokoban_deluxe_namco_system1_title from "../../assets/screenshots/sokoban_deluxe_namco_system1_title.png";

import img_shijou_saidai_no_sokoban_sega_mega_drive_001
  from "../../assets/screenshots/shijou_saidai_no_sokoban_sega_mega_drive_001.png";
import img_shijou_saidai_no_sokoban_sega_mega_drive_title
  from "../../assets/screenshots/shijou_saidai_no_sokoban_sega_mega_drive_title.png";

import img_shove_it_sega_genesis_160 from "../../assets/screenshots/shove_it_sega_genesis_160.png";
import img_shove_it_sega_genesis_title from "../../assets/screenshots/shove_it_sega_genesis_title.png";

import img_sokoban_world_pc_engine_001 from "../../assets/screenshots/sokoban_world_pc_engine_001.png";
import img_sokoban_world_pc_engine_001_mini from "../../assets/screenshots/sokoban_world_pc_engine_001_mini.png";
import img_sokoban_world_pc_engine_title from "../../assets/screenshots/sokoban_world_pc_engine_title.png";

import img_boxyboy_turbografx16_001 from "../../assets/screenshots/boxyboy_turbografx16_001.png";
import img_boxyboy_turbografx16_001_mini from "../../assets/screenshots/boxyboy_turbografx16_001_mini.png";
import img_boxyboy_turbografx16_title from "../../assets/screenshots/boxyboy_turbografx16_title.png";

import img_sokoban_sega_game_gear_001 from "../../assets/screenshots/sokoban_sega_game_gear_001.png";
import img_sokoban_sega_game_gear_title from "../../assets/screenshots/sokoban_sega_game_gear_title.png";

import img_amusement_series_i_sokoban_win_006 from "../../assets/screenshots/amusement_series_i_sokoban_win_006.png";
import img_amusement_series_i_sokoban_win_title
  from "../../assets/screenshots/amusement_series_i_sokoban_win_title.png";

import img_super_sokoban_super_famicom_001 from "../../assets/screenshots/super_sokoban_super_famicom_001.png";
import img_super_sokoban_super_famicom_title from "../../assets/screenshots/super_sokoban_super_famicom_title.png";

import img_ultimate_sokoban_psx_001 from "../../assets/screenshots/ultimate_sokoban_psx_001.png";
import img_ultimate_sokoban_psx_title from "../../assets/screenshots/ultimate_sokoban_psx_title.png";

import img_sokoban_basic1_psx_001 from "../../assets/screenshots/sokoban_basic1_psx_001.png";
import img_sokoban_basic1_psx_title from "../../assets/screenshots/sokoban_basic1_psx_title.png";

import img_sokoban_basic2_psx_001 from "../../assets/screenshots/sokoban_basic2_psx_001.png";
import img_sokoban_basic2_psx_title from "../../assets/screenshots/sokoban_basic2_psx_title.png";

import img_sokoban_nanmon_shinan_psx_001 from "../../assets/screenshots/sokoban_nanmon_shinan_psx_001.png";
import img_sokoban_nanmon_shinan_psx_title from "../../assets/screenshots/sokoban_nanmon_shinan_psx_title.png";

import img_sokoban_for_windows_win_001 from "../../assets/screenshots/sokoban_for_windows_win_001.png";
import img_sokoban_for_windows_win_title from "../../assets/screenshots/sokoban_for_windows_win_title.png";

import img_sokoban_for_macintosh_mac_001 from "../../assets/screenshots/sokoban_for_macintosh_mac_001.png";
import img_sokoban_for_macintosh_mac_title from "../../assets/screenshots/sokoban_for_macintosh_mac_title.png";

import img_sokoban_revenge_for_windows_win_001 from "../../assets/screenshots/sokoban_revenge_for_windows_win_001.png";
import img_sokoban_revenge_for_windows_win_title
  from "../../assets/screenshots/sokoban_revenge_for_windows_win_title.png";

import img_sokoban_revenge_for_macintosh_mac_001
  from "../../assets/screenshots/sokoban_revenge_for_macintosh_mac_001.png";
import img_sokoban_revenge_for_macintosh_mac_title
  from "../../assets/screenshots/sokoban_revenge_for_macintosh_mac_title.png";

import img_ultimate_sokoban_win_001 from "../../assets/screenshots/ultimate_sokoban_win_001.png";
import img_ultimate_sokoban_win_title from "../../assets/screenshots/ultimate_sokoban_win_title.png";

import img_sokoban_selection_win_001 from "../../assets/screenshots/sokoban_selection_win_001.png";
import img_sokoban_selection_win_title from "../../assets/screenshots/sokoban_selection_win_title.png";

import img_sokoban_selection_mac_001 from "../../assets/screenshots/sokoban_selection_mac_001.png";
import img_sokoban_selection_mac_title from "../../assets/screenshots/sokoban_selection_mac_title.png";

import img_sokoban_special_102_win_001 from "../../assets/screenshots/sokoban_special_102_win_001.png";
import img_sokoban_special_102_win_title from "../../assets/screenshots/sokoban_special_102_win_title.png";

import img_sokoban_basic_win_001 from "../../assets/screenshots/sokoban_basic_win_001.png";
import img_sokoban_basic_win_title from "../../assets/screenshots/sokoban_basic_win_title.png";

import img_sokoban_nanmon_shinan_win_001 from "../../assets/screenshots/sokoban_nanmon_shinan_win_001.png";
import img_sokoban_nanmon_shinan_win_title from "../../assets/screenshots/sokoban_nanmon_shinan_win_title.png";

import img_sokoban_win_011 from "../../assets/screenshots/sokoban_win_011.png";
import img_sokoban_win_title from "../../assets/screenshots/sokoban_win_title.png";

import img_sokomania_win_001 from "../../assets/screenshots/sokomania_win_001.png";
import img_sokomania_win_title from "../../assets/screenshots/sokomania_win_title.png";

import img_sokomania2_win_001 from "../../assets/screenshots/sokomania2_win_001.png";
import img_sokomania2_win_title from "../../assets/screenshots/sokomania2_win_title.png";

import img_sokoban_perfect_plus_side_a_win_001 from "../../assets/screenshots/sokoban_perfect_plus_side_a_win_001.png";
import img_sokoban_perfect_plus_side_b_win_0001
  from "../../assets/screenshots/sokoban_perfect_plus_side_b_win_0001.png";
import img_sokoban_firststep_plus_win_001 from "../../assets/screenshots/sokoban_firststep_plus_win_001.png";
import img_sokoban_revenge_reprint_win_001 from "../../assets/screenshots/sokoban_revenge_reprint_win_001.png";
import img_sokoban_smart_win_001 from "../../assets/screenshots/sokoban_smart_win_001.png";
import img_minna_no_sokoban_nintendo_switch_001
  from "../../assets/screenshots/minna_no_sokoban_nintendo_switch_001.jpg";
import img_minna_no_sokoban_nintendo_switch_title
  from "../../assets/screenshots/minna_no_sokoban_nintendo_switch_title.jpg";

import img_sokoban_touch_android_001 from "../../assets/screenshots/sokoban_touch_android_001.png";
import img_sokoban_touch_android_title_jpn from "../../assets/screenshots/sokoban_touch_android_title_jpn.png";
import img_sokoban_touch_android_title_usa from "../../assets/screenshots/sokoban_touch_android_title_usa.png";

import img_the_sokoban_nintendo_switch_001 from "../../assets/screenshots/the_sokoban_nintendo_switch_001.jpg";
import img_the_sokoban_nintendo_switch_title from "../../assets/screenshots/the_sokoban_nintendo_switch_title.jpg";

import img_sokoban_ibm_dos_001 from "../../assets/screenshots/sokoban_ibm_dos_001.png";
import img_sokoban_ibm_dos_title from "../../assets/screenshots/sokoban_ibm_dos_title.png";

import img_movebox_win_000 from "../../assets/screenshots/movebox_win_000.png";
import img_movebox_win_058 from "../../assets/screenshots/movebox_win_058.png";

import img_sokoban_c64_001 from "../../assets/screenshots/sokoban_c64_001.png";
import img_sokoban_c64_title from "../../assets/screenshots/sokoban_c64_title.png";

import img_sokoban_appleii_001 from "../../assets/screenshots/sokoban_appleii_001.png";
import img_sokoban_appleii_title from "../../assets/screenshots/sokoban_appleii_title.png";

import img_sokoban_tandy_color_computer_2_001 from "../../assets/screenshots/sokoban_tandy_color_computer_2_001.png";
import img_sokoban_tandy_color_computer_2_title
  from "../../assets/screenshots/sokoban_tandy_color_computer_2_title.png";

import img_porter_nes_001 from "../../assets/screenshots/porter_nes_001.png";
import img_porter_nes_002 from "../../assets/screenshots/porter_nes_002.png";
import img_porter_nes_003 from "../../assets/screenshots/porter_nes_003.png";
import img_porter_nes_title from "../../assets/screenshots/porter_nes_title.png";

import img_box_world_win_001 from "../../assets/screenshots/box_world_win_001.png";

import img_cheese_terminator_win_001 from "../../assets/screenshots/cheese_terminator_win_001.png";

import img_sokowin_win_001 from "../../assets/screenshots/sokowin_win_001.png";

import img_bulldozer1_win_001 from "../../assets/screenshots/bulldozer1_win_001.png";

import img_warehouse_family_ibm_dos_001 from "../../assets/screenshots/warehouse_family_ibm_dos_001.png";
import img_warehouse_family_ibm_dos_title from "../../assets/screenshots/warehouse_family_ibm_dos_title.png";

import img_pocoman_green_machine_001 from "../../assets/screenshots/pocoman_green_machine_001.png";
import img_pocoman_beginner_001 from "../../assets/screenshots/pocoman_beginner_001.png";
import img_pocoman_title from "../../assets/screenshots/pocoman_title.png";

import img_sokoban_perfect_ibm_dos_001 from "../../assets/screenshots/sokoban_perfect_ibm_dos_001.png";
import img_sokoban_perfect_ibm_dos_title from "../../assets/screenshots/sokoban_perfect_ibm_dos_title.png";

import img_sokoban_revenge_ibm_dos_001 from "../../assets/screenshots/sokoban_revenge_ibm_dos_001.png";
import img_sokoban_revenge_ibm_dos_title from "../../assets/screenshots/sokoban_revenge_ibm_dos_title.png";

import img_cargo_bay_deluxe_msdos_001 from "../../assets/screenshots/cargo_bay_deluxe_msdos_001.png";
import img_cargo_bay_deluxe_msdos_title from "../../assets/screenshots/cargo_bay_deluxe_msdos_title.png";

import img_sokoban_120g_softdisk_win_001 from "../../assets/screenshots/sokoban_120g_softdisk_win_001.png";

import img_sokoman_win_001_001 from "../../assets/screenshots/sokoman_win_001_001.png";
import img_sokoman_win_002_001 from "../../assets/screenshots/sokoman_win_002_001.png";
import img_sokoman_win_003_001 from "../../assets/screenshots/sokoman_win_003_001.png";
import img_sokoman_win_004_001 from "../../assets/screenshots/sokoman_win_004_001.png";

import img_soukoban_pocket_edition_msx_001 from "../../assets/screenshots/soukoban_pocket_edition_msx_001.png";
import img_soukoban_pocket_edition_msx_title from "../../assets/screenshots/soukoban_pocket_edition_msx_title.png";

import img_sokoban_zx_spectrum_001_screws from "../../assets/screenshots/sokoban_zx_spectrum_001_screws.png";
import img_sokoban_zx_spectrum_title_screws from "../../assets/screenshots/sokoban_zx_spectrum_title_screws.png";

import img_boxboy_msx_001 from "../../assets/screenshots/boxboy_msx_001.png";
import img_boxboy_msx_title from "../../assets/screenshots/boxboy_msx_title.png";

import img_sokoban_ds_nintendo_ds_001 from "../../assets/screenshots/sokoban_ds_nintendo_ds_001.png";

import img_storegame_win_001 from "../../assets/screenshots/storegame_win_001.png";

import img_sokobot_win_001_001_youth_profile from "../../assets/screenshots/sokobot_win_001_001_youth_profile.png";
import img_sokobot_win_intro from "../../assets/screenshots/sokobot_win_intro.png";

import img_the_incident_nes_002 from "../../assets/screenshots/the_incident_nes_002.png";
import img_the_incident_nes_title from "../../assets/screenshots/the_incident_nes_title.png";

import img_skbn_sega_master_system_002 from "../../assets/screenshots/skbn_sega_master_system_002.png";
import img_skbn_sega_master_system_title from "../../assets/screenshots/skbn_sega_master_system_title.png";

export const gamesJapanSokoban1: Array<InterfaceGameItem> = [
    {
        title: "Sokoban (PC-8801)",
        year: "1982 December",
        publisher: ThinkingRabbit,
        images: [img_sokoban_pc8801_001, img_sokoban_pc8801_title],
    },
    {
        title: "Sokoban (PC-8001 mk2)",
        year: "1982 December",
        publisher: ThinkingRabbit,
        images: [img_sokoban_pc8001_mk2_001, img_sokoban_pc8001_mk2_title],
    },
    {
        title: "Sokoban (PC-6001 32K)",
        year: "1983",
        publisher: ThinkingRabbit,
        images: [img_sokoban_pc6001_32k_001, img_sokoban_pc6001_32k_title],
    },
    {
        title: "Sokoban (PC-6001 mk2)",
        year: "1983",
        publisher: ThinkingRabbit,
        images: [img_sokoban_pc6001_mk2_001, img_sokoban_pc6001_mk2_title],
    },
    {
        title: "Sokoban (MZ-2000 Mono)",
        year: "1983",
        publisher: ThinkingRabbit,
        images: [img_sokoban_mz2000_mono_001, img_sokoban_mz2000_mono_title],
    },
    {
        title: "Sokoban (MZ-2000 Color)",
        year: "1983",
        publisher: ThinkingRabbit,
        images: [img_sokoban_mz2000_color_001, img_sokoban_mz2000_color_title],
    },
    {
        title: "Sokoban (Sharp-X1)",
        year: "1983",
        publisher: ThinkingRabbit,
        images: [img_sokoban_sharp_x1_001, img_sokoban_sharp_x1_title],
    },
];

export const gamesJapanSokoban1Extra: Array<InterfaceGameItem> = [
    {
        title: "Sokoban Extra Edition (PC-8801)",
        year: "1983 August",
        publisher: "PCマガジン",
        images: [img_sokoban_extra_pc8801_001, img_sokoban_extra_pc8801_title],
    },
    {
        title: "Sokoban Extra (PC-6001 32K)",
        year: "1984 May",
        publisher: "PCマガジン",
        images: [
            img_sokoban_extra_pc6001_32k_001,
            img_sokoban_extra_pc6001_32k_title,
        ],
    },
];

export const gamesJapanSokoban2: Array<InterfaceGameItem> = [
    {
        title: "Sokoban 2 (PC-8801)",
        year: "1984 February",
        publisher: ThinkingRabbit,
        images: [img_sokoban2_pc8801_001, img_sokoban2_pc8801_title],
    },
    {
        title: "Sokoban 2 (PC-8001 mk2)",
        year: "1984 March",
        publisher: ThinkingRabbit,
        images: [img_sokoban2_pc8001_mk2_001, img_sokoban2_pc8001_mk2_title],
    },
    {
        title: "Sokoban 2 (PC-6001 mk2)",
        year: "1984 November",
        publisher: ThinkingRabbit,
        images: [
            img_sokoban2_pc6001_mk2_001,
            img_sokoban2_pc6001_mk2_title_b,
            img_sokoban2_pc6001_mk2_title_a,
        ],
    },
    {
        title: "Sokoban 2 (PC-6001 32k)",
        year: "1984 December",
        publisher: ThinkingRabbit,
        images: [
            img_sokoban2_pc6001_32k_001,
            img_sokoban2_pc6001_32k_title_b,
            img_sokoban2_pc6001_32k_title_a,
        ],
    },
    {
        title: "Sokoban 2 (Sharp-X1)",
        year: "1984",
        publisher: ThinkingRabbit,
        images: [img_sokoban2_sharp_x1_001, img_sokoban2_sharp_x1_title],
    },
];

export const gamesJapan1984to1989various: Array<InterfaceGameItem> = [
    {
        title: "Sokoban Tiny Editor (MZ-700)",
        year: "1984 March",
        publisher: "MICRO",
        images: [
            img_sokoban_tiny_editor_mz700_001,
            img_sokoban_tiny_editor_mz700_title,
        ],
    },
    {
        title: "Sokoban Tiny Editor (PC-8001)",
        year: "1984 April",
        publisher: "MICRO",
        images: [
            img_sokoban_tiny_editor_pc8001_001,
            img_sokoban_tiny_editor_pc8001_title,
        ],
    },
    {
        title: "Sokoban (SMC-777)",
        year: "1985 January",
        publisher: "FLOPPY Magazine",
        images: [img_sokoban_smc77_001, img_sokoban_smc77_title],
    },
    {
        title: "Sokoban (EPOCH Game pocket computer)",
        year: "1985 April",
        publisher: "Epoch Co.",
        images: [img_sokoban_epoch_001, img_sokoban_epoch_title],
    },
    {
        title: "Sokoban Rom Pack (MSX)",
        year: "1984 May",
        publisher: "ASCII Corporation",
        images: [img_sokoban_msx_rom_001, img_sokoban_msx_rom_title],
    },
    {
        title: "Sokoban Tape Pack (MSX)",
        year: "1984 November",
        publisher: "ASCII Corporation",
        images: [img_sokoban_msx_tape_001, img_sokoban_msx_tape_title],
    },
    {
        title: "Sokoban (Sega SG-1000 II)",
        year: "1985 December",
        publisher: "Sega",
        images: [img_sokoban_sega_sg1000ii_001, img_sokoban_sega_sg1000ii_title],
    },
    {
        title: "Namida No Sokoban Special (Famicom)",
        year: "1986 July",
        publisher: "ASCII Corporation",
        images: [
            img_namida_no_sokoban_special_famicom_001,
            img_namida_no_sokoban_special_famicom_001big,
            img_namida_no_sokoban_special_famicom_title,
        ],
    },
    {
        title: "Sokoban for Pasopia 7 (Pasopia 7)",
        year: "1987 November",
        publisher: "Oh! PASOPIA",
        images: [
            img_sokoban_for_pasopia7_001,
            img_sokoban_for_pasopia7_menu_play,
            img_sokoban_for_pasopia7_menu,
            img_sokoban_for_pasopia7_title,
        ],
    },
    {
        title: "Soko-ban Perfect (MSX2)",
        year: "1989",
        publisher: "MICRO CABIN",
        images: [img_sokoban_perfect_msx2_001, img_sokoban_perfect_msx2_title],
    },
    {
        title: "Soko-ban Perfect (Sharp X-68000)",
        year: "1989",
        publisher: ThinkingRabbit,
        images: [
            img_sokoban_perfect_sharp_x68000_001,
            img_sokoban_perfect_sharp_x68000_title,
        ],
    },
    {
        title: "Soko-ban Perfect (FM Towns)",
        year: "1989",
        publisher: ThinkingRabbit,
        images: [
            img_sokoban_perfect_fm_towns_001,
            img_sokoban_perfect_fm_towns_title,
        ],
    },
];

export const gamesJapanPC9801: Array<InterfaceGameItem> = [
    {
        title: "Soko-ban Perfect (PC-9801)",
        year: "1989",
        publisher: ThinkingRabbit,
        images: [img_sokoban_perfect_pc98_001, img_sokoban_perfect_pc98_title],
    },
    {
        title: "Soko-ban Revenge (PC-9801)",
        year: "1991 November 27",
        publisher: ThinkingRabbit,
        images: [img_sokoban_revenge_pc98_001, img_sokoban_revenge_pc98_title],
    },
    {
        title: "Soko-ban Select 30 (PC-9801)",
        year: "1991",
        publisher: "KAO",
        images: [img_sokoban_select30_pc98_001, img_sokoban_select30_pc98_title],
    },
];

export const gamesJapanOthers: Array<InterfaceGameItem> = [
    {
        title: "Soko-ban Revenge (J-3100)",
        year: "1993 November",
        publisher: ThinkingRabbit,
        images: [img_sokoban_revenge_j3100_001, img_sokoban_revenge_j3100_title],
    },
];

export const gamesJapanGameBoy: Array<InterfaceGameItem> = [
    {
        title: "Soko-ban (Game Boy)",
        year: "1989 August",
        publisher: "Pony Canyon",
        images: [img_sokoban_gameboy_001, img_sokoban_gameboy_title],
    },
    {
        title: "Soko-ban 2 (Game Boy)",
        year: "1990 June",
        publisher: "Pony Canyon",
        images: [img_sokoban2_gameboy_001, img_sokoban2_gameboy_title],
    },
];

export const gamesJapan1990to1993: Array<InterfaceGameItem> = [
    {
        title: "Sokoban DELUXE (Namco System 1)",
        year: "1990",
        publisher: "Namco",
        images: [
            img_sokoban_deluxe_namco_system1_001,
            img_sokoban_deluxe_namco_system1_title,
        ],
    },
    {
        title: "Shijou Saidai No Sokoban (Sega Mega Drive)",
        year: "1990 January",
        publisher: "Masaya",
        images: [
            img_shijou_saidai_no_sokoban_sega_mega_drive_001,
            img_shijou_saidai_no_sokoban_sega_mega_drive_title,
        ],
    },
    {
        title: "Shove It! ...The Warehouse Game (Sega Genesis)",
        year: "1990 May",
        publisher: "DreamWorks",
        images: [
            img_shove_it_sega_genesis_160,
            img_shove_it_sega_genesis_title,
        ],
    },
    {
        title: "Sokoban (Sega Game Gear)",
        year: "1990 December",
        publisher: "Riverhill Soft",
        images: [img_sokoban_sega_game_gear_001, img_sokoban_sega_game_gear_title],
    },
    {
        title: "Sokoban World (PC Engine)",
        year: "1990 March",
        publisher: "Media Rings",
        images: [
            img_sokoban_world_pc_engine_001,
            img_sokoban_world_pc_engine_001_mini,
            img_sokoban_world_pc_engine_title,
        ],
    },
    {
        title: "Boxyboy (TurboGrafx-16)",
        year: "1990",
        publisher: "NEC",
        images: [
            img_boxyboy_turbografx16_001,
            img_boxyboy_turbografx16_001_mini,
            img_boxyboy_turbografx16_title,
        ],
    },
    {
        title: "Amusement Series I Sokoban (Windows 3.0)",
        year: "1992",
        publisher: "Pack-In-Video",
        images: [
            img_amusement_series_i_sokoban_win_006,
            img_amusement_series_i_sokoban_win_title,
        ],
    },
    {
        title: "Soko-ban Revenge Sx-68K (Sharp X68000)",
        year: "1993",
        publisher: ThinkingRabbit,
        images: [
            img_sokoban_revenge_sx_68k_sharp_x68000_001,
            img_sokoban_revenge_sx_68k_sharp_x68000_title,
        ],
    },
    {
        title: "Super Sokoban (Super Famicom)",
        year: "1993 January",
        publisher: "Pack-In-Video",
        images: [
            img_super_sokoban_super_famicom_001,
            img_super_sokoban_super_famicom_title,
        ],
    },
];

export const gamesJapan1995to2000: Array<InterfaceGameItem> = [
    {
        title: "Sokoban for Windows (Windows)",
        year: "1995",
        publisher: Itochu,
        images: [
            img_sokoban_for_windows_win_001,
            img_sokoban_for_windows_win_title,
        ],
    },
    {
        title: "Sokoban for Macintosh (Macintosh)",
        year: "1996",
        publisher: Itochu,
        images: [
            img_sokoban_for_macintosh_mac_001,
            img_sokoban_for_macintosh_mac_title,
        ],
    },
    {
        title: "Sokoban Revenge for Windows (Windows)",
        year: "1996",
        publisher: Itochu,
        images: [
            img_sokoban_revenge_for_windows_win_001,
            img_sokoban_revenge_for_windows_win_title,
        ],
    },
    {
        title: "Sokoban Revenge for Macintosh (Macintosh)",
        year: "1996",
        publisher: Itochu,
        images: [
            img_sokoban_revenge_for_macintosh_mac_001,
            img_sokoban_revenge_for_macintosh_mac_title,
        ],
    },
    {
        title: "Kyuukuoku No Sokoban / Ultimate Sokoban  (PlayStation)",
        year: "1996",
        publisher: Itochu,
        images: [
            img_ultimate_sokoban_psx_001,
            img_ultimate_sokoban_psx_title,
        ],
    },
    {
        title: "Sokoban Basic (PlayStation)",
        year: "1997 August",
        publisher: Itochu,
        images: [img_sokoban_basic1_psx_001, img_sokoban_basic1_psx_title],
    },
    {
        title: "Sokoban Selection (Windows)",
        year: "1997 October",
        publisher: Itochu,
        images: [img_sokoban_selection_win_001, img_sokoban_selection_win_title],
    },
    {
        title: "Sokoban Selection (Macintosh)",
        year: "1997 December",
        publisher: Itochu,
        images: [img_sokoban_selection_mac_001, img_sokoban_selection_mac_title],
    },
    {
        title: "Sokoban Special 102 (Windows)",
        year: "1998 January",
        publisher: Itochu,
        images: [
            img_sokoban_special_102_win_001,
            img_sokoban_special_102_win_title,
        ],
    },
    {
        title: "Kyuukuoku No Sokoban / Ultimate Sokoban (Windows)",
        year: "1998 March",
        publisher: Itochu,
        images: [
            img_ultimate_sokoban_win_001,
            img_ultimate_sokoban_win_title,
        ],
    },
    {
        title: "Sokoban Basic 2 (PlayStation)",
        year: "1998 August",
        publisher: Itochu,
        images: [img_sokoban_basic2_psx_001, img_sokoban_basic2_psx_title],
    },
    {
        title: "Sokoban Basic (Windows)",
        year: "1998",
        publisher: Unbalance,
        images: [img_sokoban_basic_win_001, img_sokoban_basic_win_title],
    },
    {
        title: "Sokoban Nanmon Shinan (PlayStation)",
        year: "1999 April",
        publisher: Unbalance,
        images: [
            img_sokoban_nanmon_shinan_psx_001,
            img_sokoban_nanmon_shinan_psx_title,
        ],
    },
    {
        title: "Sokoban Nanmon Shinan (Windows)",
        year: "2000 June",
        publisher: Unbalance,
        images: [
            img_sokoban_nanmon_shinan_win_001,
            img_sokoban_nanmon_shinan_win_title,
        ],
    },
    {
        title: "Sokoban (Windows)",
        year: "2000",
        publisher: Unbalance,
        images: [img_sokoban_win_011, img_sokoban_win_title],
    },
];

export const gamesJapan2015to2020: Array<InterfaceGameItem> = [
    {
        title: "Sokoban Perfect Plus Side A (Windows)",
        year: "2015 September 25",
        publisher: Falcon,
        images: [img_sokoban_perfect_plus_side_a_win_001],
    },
    {
        title: "Sokoban Perfect Plus Side B (Windows)",
        year: "2015 November 20",
        publisher: Falcon,
        images: [img_sokoban_perfect_plus_side_b_win_0001],
    },
    {
        title: "Sokoban Firststep Plus (Windows)",
        year: "2016 February 09",
        publisher: Falcon,
        images: [img_sokoban_firststep_plus_win_001],
    },
    {
        title: "Sokoban Revenge Reprint (Windows)",
        year: "2016 April 25",
        publisher: Falcon,
        images: [img_sokoban_revenge_reprint_win_001],
    },
    {
        title: "Sokoban Smart (Windows)",
        year: "2018 May",
        publisher: Falcon,
        images: [img_sokoban_smart_win_001],
    },
    {
        title: "Minna No Sokoban (Nintendo Switch)",
        year: "2019 May 23",
        publisher: Unbalance,
        images: [
            img_minna_no_sokoban_nintendo_switch_001,
            img_minna_no_sokoban_nintendo_switch_title,
        ],
    },
];

export const gamesInternational: Array<InterfaceGameItem> = [
    {
        title: "Sokoban Touch (Android)",
        year: "2016 March 15",
        publisher: Falcon,
        images: [
            img_sokoban_touch_android_001,
            img_sokoban_touch_android_title_jpn,
            img_sokoban_touch_android_title_usa,
        ],
    },
    {
        title: "The Sokoban (Nintendo Switch)",
        year: "2021 October 28",
        publisher: Unbalance,
        images: [
            img_the_sokoban_nintendo_switch_001,
            img_the_sokoban_nintendo_switch_title,
        ],
    },
];

export const gamesSpectrumHoloByte: Array<InterfaceGameItem> = [
    {
        title: "Soko-Ban (IBM PC DOS)",
        year: "1988",
        publisher: "Spectrum HoloByte",
        images: [img_sokoban_ibm_dos_001, img_sokoban_ibm_dos_title],
    },
    {
        title: "Soko-Ban (Commodore 64)",
        year: "1988",
        publisher: "Spectrum HoloByte",
        images: [img_sokoban_c64_001, img_sokoban_c64_title],
    },
    {
        title: "Soko-Ban (Apple II)",
        year: "1988",
        publisher: "Spectrum HoloByte",
        images: [img_sokoban_appleii_001, img_sokoban_appleii_title],
    },
    {
        title: "Soko-Ban (Tandy Color Computer 2)",
        year: "1988",
        publisher: "Tandy Corporation",
        images: [
            img_sokoban_tandy_color_computer_2_001,
            img_sokoban_tandy_color_computer_2_title,
        ],
    },
];

export const gamesChina: Array<InterfaceGameItem> = [
    {
        title: "Warehouse Family (IBM PC DOS)",
        year: "1990",
        publisher: "Softstar",
        images: [
            img_warehouse_family_ibm_dos_001,
            img_warehouse_family_ibm_dos_title,
        ],
    },
    {
        title: "Soko-ban Perfect (IBM PC DOS)",
        year: "1995 September",
        publisher: "Softstar",
        images: [
            img_sokoban_perfect_ibm_dos_001,
            img_sokoban_perfect_ibm_dos_title,
        ],
    },
    {
        title: "Soko-ban Revenge (IBM PC DOS)",
        year: "1995 September",
        publisher: "Softstar",
        images: [
            img_sokoban_revenge_ibm_dos_001,
            img_sokoban_revenge_ibm_dos_title,
        ],
    },
    {
        title: "MoveBox (Windows)",
        year: "2002",
        publisher: "Yi-Century",
        images: [
            img_movebox_win_000,
            img_movebox_win_058,
        ],
    },
];

export const gamesOthersJapan: Array<InterfaceGameItem> = [
    {
        title: "Soko-mania (Windows)",
        year: "2003",
        publisher: "SALVA",
        images: [img_sokomania_win_001, img_sokomania_win_title],
    },
    {
        title: "Soko-mania 2 (Windows)",
        year: "2002",
        publisher: "MSD-JAPAN",
        images: [img_sokomania2_win_001, img_sokomania2_win_title],
    },
    {
        title: "StoreGame (Windows)",
        year: "2008",
        publisher: "Magnolia",
        images: [img_storegame_win_001],
    },
];

export const gamesOthersOutsideJapan: Array<InterfaceGameItem> = [
    {
        title: "Porter (Nintendo)",
        year: "1991",
        publisher: "Mega Soft (NTDEC)",
        images: [
            img_porter_nes_001,
            img_porter_nes_002,
            img_porter_nes_003,
            img_porter_nes_title
        ],
    },
    {
        title: "Box World (Windows)",
        year: "1992",
        publisher: "Jeng-Long Jiang",
        images: [img_box_world_win_001],
    },
    {
        title: "Cheese Terminator (Windows)",
        year: "1992",
        publisher: "Microsoft Corporation",
        images: [img_cheese_terminator_win_001],
    },
    {
        title: "SokoWin (Windows)",
        year: "1992",
        publisher: "Erik Pallemans",
        images: [img_sokowin_win_001],
    },
    {
        title: "Bulldozer (Windows)",
        year: "1995",
        publisher: "The Code Zone",
        images: [img_bulldozer1_win_001],
    },
    {
        title: "Cargo Bay Deluxe (MS-DOS)",
        year: "1995",
        publisher: "MVP Software",
        images: [img_cargo_bay_deluxe_msdos_001, img_cargo_bay_deluxe_msdos_title],
    },
    {
        title: "PocoMan (Windows)",
        year: "1996",
        publisher: "Sleepless",
        images: [
            img_pocoman_green_machine_001,
            img_pocoman_beginner_001,
            img_pocoman_title
        ],
    },
    {
        title: "Sokoban 1.20G (Windows)",
        year: "1997",
        publisher: "Softdisk",
        images: [img_sokoban_120g_softdisk_win_001],
    },
    {
        title: "SokoMan 2.01 (Windows)",
        year: "2002 February 10",
        publisher: "Lena Pankratova",
        images: [
            img_sokoman_win_001_001,
            img_sokoman_win_002_001,
            img_sokoman_win_003_001,
            img_sokoman_win_004_001,
        ],
    },
    {
        title: "Soukoban Pocket Edition (MSX)",
        year: "2004",
        publisher: "Karoshi Corporation",
        images: [
            img_soukoban_pocket_edition_msx_001,
            img_soukoban_pocket_edition_msx_title,
        ],
    },
    {
        title: "Sokoban (ZX Spectrum 128K)",
        year: "2006",
        publisher: "Compiler Software",
        images: [
            img_sokoban_zx_spectrum_001_screws,
            img_sokoban_zx_spectrum_title_screws,
        ],
    },
    {
        title: "Boxboy (MSX)",
        year: "2008",
        publisher: "Icon Games",
        images: [img_boxboy_msx_001, img_boxboy_msx_title],
    },
    {
        title: "Sokoban DS (Nintendo DS)",
        year: "2008",
        publisher: "RTL Games GmbH",
        images: [img_sokoban_ds_nintendo_ds_001],
    },
    {
        title: "SokoBot (Windows)",
        year: "2009",
        publisher: "Joven Club de Computación y Electrónica",
        images: [img_sokobot_win_001_001_youth_profile, img_sokobot_win_intro],
    },
    {
        title: "The Incident (Remastered) (Nintendo)",
        year: "2020",
        publisher: "KHAN Games",
        images: [img_the_incident_nes_002, img_the_incident_nes_title],
    },
    {
        title: "SKBN v2 (Sega Master System)",
        year: "2022",
        publisher: "Raphaël Assenat",
        images: [img_skbn_sega_master_system_002, img_skbn_sega_master_system_title],
    },
];
