import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faImages} from '@fortawesome/free-regular-svg-icons'
import {InterfaceGameItem} from '../../interfaces/interface-game-item';

type GameCardProps = {
    game: InterfaceGameItem,
};

type GameCardState = {
    image: string,
    index: number,
    loadingImage: boolean,
    errorImage: boolean,
}

export class GameCard extends React.Component<GameCardProps, GameCardState> {

    constructor(props: any) {
        super(props);
        const {images} = this.props.game;
        const index = 0;
        this.state = {
            image: images[index],
            index: index,
            loadingImage: true,
            errorImage: false,
        };
        this.handleCardClick = this.handleCardClick.bind(this);
        this.handleLoadedImage = this.handleLoadedImage.bind(this);
        this.handleErrorImage = this.handleErrorImage.bind(this);
        this.handleRetryLoadImage = this.handleRetryLoadImage.bind(this);
    }

    handleCardClick() {
        const {images} = this.props.game;
        const {index, loadingImage} = this.state;
        if (loadingImage) {
            return;
        }
        let newIndex = index + 1;
        if (newIndex > images.length - 1) {
            newIndex = 0;
        }
        const newImage = images[newIndex];
        this.setState({image: newImage, index: newIndex, loadingImage: true});
    }

    handleLoadedImage() {
        this.setState({loadingImage: false, errorImage: false});
    }

    handleErrorImage() {
        this.setState({loadingImage: false, errorImage: true});
    }

    handleRetryLoadImage() {
        this.setState({loadingImage: true, errorImage: false});
    }

    render() {

        const {image, loadingImage, errorImage} = this.state;
        const {title, year, publisher, images, comment} = this.props.game;

        return (
            <div className="card mb-2">

                <div className="card-img-container">

                    {loadingImage &&
                        <div className="spinner">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }

                    {errorImage &&
                        <div className="text-center">
                            <p className="mt-3">Error loading image</p>
                            <button type="button" className="btn btn-info"
                                    onClick={this.handleRetryLoadImage}
                            >Retry
                            </button>
                        </div>
                    }

                    {!errorImage &&
                        <img
                            className={`card-img-top screenshot ${loadingImage ? "semitransparent" : ""}`}
                            src={image}
                            alt=""
                            onLoad={this.handleLoadedImage}
                            onError={this.handleErrorImage}
                        />
                    }

                </div>

                <div className="card-body">
                    <div>
                        <h6 className="card-title">
                            {title}
                            {images.length > 1 &&
                                <button
                                    type="button"
                                    className="btn btn-sm btn-slide btn-card-game-carousel text-dark float-right"
                                    onClick={this.handleCardClick}
                                >
                                    <FontAwesomeIcon icon={faImages}/>
                                </button>
                            }
                        </h6>
                    </div>
                    <p className="text-muted card-text">
                        <small>{year} - {publisher}</small>
                    </p>
                    {
                        comment && (
                            <p className="text-muted card-text">
                                <small>{comment}</small>
                            </p>
                        )
                    }
                </div>
            </div>
        );
    }
}
