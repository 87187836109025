import React from 'react';
import {BrowserRouter} from "react-router-dom";
import './index.css';

// import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/css/new-age.css';

import {hydrate, render} from "react-dom";

import AppRoutes from "./app/app.routes";
import AppComponent from "./app/app.component";


const rootElement = document.getElementById("root")!;

if (rootElement.hasChildNodes()) {
    hydrate(<React.StrictMode>
        <BrowserRouter>
            <AppRoutes/>
            <AppComponent/>
        </BrowserRouter>
    </React.StrictMode>, rootElement);
} else {
    render(<React.StrictMode>
        <BrowserRouter>
            <AppRoutes/>
            <AppComponent/>
        </BrowserRouter>
    </React.StrictMode>, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
