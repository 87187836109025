import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import HomePageComponent from "./pages/home-page/home-page";
import InfoPageComponent from "./pages/info-page/info-page";
import ArticlesPageComponent from "./pages/articles-page/articles-page";
import NotFoundPageComponent from "./pages/not-found-page/not-found-page";
import ArticleSokobanIbmPc from "./pages/articles-page/article-sokoban-ibm-pc";
import ArticleSokobanPc8801 from "./pages/articles-page/article-sokoban-pc8801";
import ArticleSokobanExtraEditionPc8801 from "./pages/articles-page/article-sokoban-extra-edition-pc8801";
import ArticleSokobanPc8801PortFromMulti8 from "./pages/articles-page/article-sokoban-pc8801-port-from-multi8";
import MyConversationWithMrHiroyukiHimabayashi
        from "./pages/articles-page/my-conversation-with-mr-hiroyuki-himabayashi";
import ArticleSokobanPc8801English from "./pages/articles-page/article-sokoban-pc8801-english";
import ArticleSokoban80sProductCodes from "./pages/articles-page/article-sokoban-80s-product-codes";

const AppRoutes = () => (
    <div>
            <Switch>
                    <Redirect exact from="/home" to="/"/>
                    <Route exact path="/" component={HomePageComponent}/>
                    <Route exact path="/info" component={InfoPageComponent}/>
                    <Route exact path="/articles" component={ArticlesPageComponent}/>
                    <Route
                        exact
                        path="/articles/sokoban-80s-product-codes"
                        component={ArticleSokoban80sProductCodes}
                    />
                    <Route
                        exact
                        path="/articles/sokoban-ibm-pc"
                        component={ArticleSokobanIbmPc}
                    />
                    <Route
                        exact
                        path="/articles/sokoban-pc8801"
                        component={ArticleSokobanPc8801}
                    />
                    <Route
                        exact
                        path="/articles/sokoban-extra-edition-pc8801"
                        component={ArticleSokobanExtraEditionPc8801}
                    />
                    <Route
                        exact
                        path="/articles/sokoban-pc8801-port-from-multi8"
                        component={ArticleSokobanPc8801PortFromMulti8}
                    />
                    <Route
                        exact
                        path="/articles/my-conversation-with-mr-hiroyuki"
                        component={MyConversationWithMrHiroyukiHimabayashi}
                    />
                    <Route
                        exact
                        path="/articles/sokoban-pc8801-english"
                        component={ArticleSokobanPc8801English}
                    />
                    <Route component={NotFoundPageComponent}/>
            </Switch>
    </div>
);

export default AppRoutes;
