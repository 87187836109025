import React from "react";
import {Link} from "react-router-dom";

export default function ArticlesPage() {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 rounded bg-white">
                        <h3>Articles</h3>
                        <ul className="nav navbar-nav ml-auto mb-3">
                            <li className="nav-item mb-1" role="presentation">
                                <Link
                                    to="/articles/sokoban-ibm-pc"
                                    className="nav-link js-scroll-trigger text-primary"
                                >
                                    Soko-ban (IBM-PC)
                                </Link>
                            </li>
                            <li className="nav-item mb-1" role="presentation">
                                <Link
                                    to="/articles/sokoban-pc8801"
                                    className="nav-link js-scroll-trigger text-primary"
                                >
                                    Sokoban (PC-8801)
                                </Link>
                            </li>
                            <li className="nav-item mb-1" role="presentation">
                                <Link
                                    to="/articles/sokoban-extra-edition-pc8801"
                                    className="nav-link js-scroll-trigger text-primary"
                                >
                                    Sokoban Extra Edition (PC-8801)
                                </Link>
                            </li>
                            <li className="nav-item mb-1" role="presentation">
                                <Link
                                    to="/articles/sokoban-pc8801-port-from-multi8"
                                    className="nav-link js-scroll-trigger text-primary"
                                >
                                    Sokoban (PC-8801 Port from Multi-8)
                                </Link>
                            </li>
                            <li className="nav-item mb-1" role="presentation">
                                <Link
                                    to="/articles/my-conversation-with-mr-hiroyuki"
                                    className="nav-link js-scroll-trigger text-primary"
                                >
                                    My conversation with Mr Hiroyuki Imabayashi
                                </Link>
                            </li>
                            <li className="nav-item mb-1" role="presentation">
                                <Link
                                    to="/articles/sokoban-pc8801-english"
                                    className="nav-link js-scroll-trigger text-primary"
                                >
                                    Sokoban (PC-8801) (English)
                                </Link>
                            </li>
                            <li className="nav-item mb-1" role="presentation">
                                <Link
                                    to="/articles/sokoban-80s-product-codes"
                                    className="nav-link js-scroll-trigger text-primary"
                                >
                                    Sokoban 80s Product Codes
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}
