import React from "react";

import {
    gamesChina,
    gamesInternational,
    gamesJapan1984to1989various,
    gamesJapan1990to1993,
    gamesJapan1995to2000,
    gamesJapan2015to2020,
    gamesJapanGameBoy, gamesJapanOthers,
    gamesJapanPC9801,
    gamesJapanSokoban1,
    gamesJapanSokoban1Extra,
    gamesJapanSokoban2,
    gamesOthersJapan,
    gamesOthersOutsideJapan,
    gamesSpectrumHoloByte,
} from "../../data/sokoban.data";
import {variantsPushBoxesCollectItems} from "../../data/variants.data";
import {GameCard} from "../game-card/game-card";

import img_sokoban_items_1_cmontiers from "../../../assets/img/sokoban_items_1_cmontiers.jpg";

export default function InfoPage() {
    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-12 rounded bg-white">
                        <h3>History</h3>
                        <p>
                            Sokoban (倉庫番) is a puzzle video game created in Japan in 1981
                            by Hiroyuki Imabayashi.
                        </p>
                        <p>
                            In 1982, he founded his software company Thinking Rabbit and
                            began to publish and license many Sokoban games.
                        </p>
                        <img
                            className="img-thumbnail text-center mb-3"
                            src={img_sokoban_items_1_cmontiers}
                            alt=""
                        ></img>
                        <p>
                            After the closure of Thinking Rabbit around 2000, Falcon, another
                            software company, acquired the Sokoban and Thinking Rabbit
                            trademarks. Since then, Falcon has continued to develop and
                            license official Sokoban games, maintaining the game's
                            popularity today.
                        </p>
                        <p>
                            In addition, over the years, other companies and individuals have
                            ventured into developing games with similar mechanics. These games,
                            often called Sokoban clones, also significantly influenced
                            its popularity.
                        </p>
                        <p>
                            To get an idea of what the official games and clones look like,
                            see the screenshots below:
                        </p>
                    </div>

                    <div className="col-12 mt-2 mb-2 rounded bg-white">
                        <h6>Japan - Sokoban 1</h6>
                    </div>
                    <div className="row mb-2">
                        {gamesJapanSokoban1.map((game, index) => (
                            <div key={index} className="col-12 col-sm-12 col-lg-4 mb-2">
                                <GameCard game={game}/>
                            </div>
                        ))}
                    </div>

                    <div className="col-12 mt-2 mb-2 rounded bg-white">
                        <h6>Japan - Sokoban 1 Extra</h6>
                    </div>
                    <div className="row mb-2">
                        {gamesJapanSokoban1Extra.map((game, index) => (
                            <div key={index} className="col-12 col-sm-12 col-lg-4 mb-2">
                                <GameCard game={game}/>
                            </div>
                        ))}
                    </div>

                    <div className="col-12 mt-2 mb-2 rounded bg-white">
                        <h6>Japan - Sokoban 2</h6>
                    </div>
                    <div className="row mb-2">
                        {gamesJapanSokoban2.map((game, index) => (
                            <div key={index} className="col-12 col-sm-12 col-lg-4 mb-2">
                                <GameCard game={game}/>
                            </div>
                        ))}
                    </div>
                    <div className="col-12 mt-2 mb-2 rounded bg-white">
                        <h6>Japan releases 1984-1989 (various)</h6>
                    </div>
                    <div className="row mb-2">
                        {gamesJapan1984to1989various.map((game, index) => (
                            <div key={index} className="col-12 col-sm-12 col-lg-4 mb-2">
                                <GameCard game={game}/>
                            </div>
                        ))}
                    </div>
                    <div className="col-12 mt-2 mb-2 rounded bg-white">
                        <h6>Japan releases PC-9801</h6>
                    </div>
                    <div className="row mb-2">
                        {gamesJapanPC9801.map((game, index) => (
                            <div key={index} className="col-12 col-sm-12 col-lg-4 mb-2">
                                <GameCard game={game}/>
                            </div>
                        ))}
                    </div>
                    <div className="col-12 mt-2 mb-2 rounded bg-white">
                        <h6>Japan releases Others</h6>
                    </div>
                    <div className="row mb-2">
                        {gamesJapanOthers.map((game, index) => (
                            <div key={index} className="col-12 col-sm-12 col-lg-6 mb-2">
                                <GameCard game={game}/>
                            </div>
                        ))}
                    </div>

                    <div className="col-12 mt-2 mb-2 rounded bg-white">
                        <h6>Japan releases Game Boy</h6>
                    </div>
                    <div className="row mb-2">
                        {gamesJapanGameBoy.map((game, index) => (
                            <div key={index} className="col-12 col-sm-12 col-lg-4 mb-2">
                                <GameCard game={game}/>
                            </div>
                        ))}
                    </div>

                    <div className="col-12 mt-2 mb-2 rounded bg-white">
                        <h6>Japan releases 1990-1993</h6>
                    </div>
                    <div className="row mb-2">
                        {gamesJapan1990to1993.map((game, index) => (
                            <div key={index} className="col-12 col-sm-12 col-lg-4 mb-2">
                                <GameCard game={game}/>
                            </div>
                        ))}
                    </div>

                    <div className="col-12 mt-2 mb-2 rounded bg-white">
                        <h6>Japan releases 1995-2000</h6>
                    </div>
                    <div className="row mb-2">
                        {gamesJapan1995to2000.map((game, index) => (
                            <div key={index} className="col-12 col-sm-12 col-lg-4 mb-2">
                                <GameCard game={game}/>
                            </div>
                        ))}
                    </div>

                    <div className="col-12 mt-2 mb-2 rounded bg-white">
                        <h6>Japan releases 2015-2020</h6>
                    </div>
                    <div className="row mb-2">
                        {gamesJapan2015to2020.map((game, index) => (
                            <div key={index} className="col-12 col-sm-12 col-lg-4 mb-2">
                                <GameCard game={game}/>
                            </div>
                        ))}
                    </div>
                    <div className="col-12 mt-2 mb-2 rounded bg-white">
                        <h6>International releases</h6>
                    </div>
                    <div className="row mb-2">
                        {gamesInternational.map((game, index) => (
                            <div key={index} className="col-12 col-sm-12 col-lg-4 mb-2">
                                <GameCard game={game}/>
                            </div>
                        ))}
                    </div>
                    <div className="col-12 mt-2 mb-2 rounded bg-white">
                        <h6>U.S. releases by Spectrum HoloByte</h6>
                    </div>
                    <div className="row mb-2">
                        {gamesSpectrumHoloByte.map((game, index) => (
                            <div key={index} className="col-12 col-sm-12 col-lg-4 mb-2">
                                <GameCard game={game}/>
                            </div>
                        ))}
                    </div>
                    <div className="col-12 mt-2 mb-2 rounded bg-white">
                        <h6>China releases</h6>
                    </div>
                    <div className="row mb-2">
                        {gamesChina.map((game, index) => (
                            <div key={index} className="col-12 col-sm-12 col-lg-4 mb-2">
                                <GameCard game={game}/>
                            </div>
                        ))}
                    </div>
                    <div className="col-12 mt-2 mb-2 rounded bg-white">
                        <h6>Others (Japan)</h6>
                    </div>
                    <div className="row mb-2">
                        {gamesOthersJapan.map((game, index) => (
                            <div key={index} className="col-12 col-sm-12 col-lg-4 mb-2">
                                <GameCard game={game}/>
                            </div>
                        ))}
                    </div>
                    <div className="col-12 mt-2 mb-2 rounded bg-white">
                        <h6>Others (International)</h6>
                    </div>
                    <div className="row mb-2">
                        {gamesOthersOutsideJapan.map((game, index) => (
                            <div key={index} className="col-12 col-sm-12 col-lg-4 mb-2">
                                <GameCard game={game}/>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mb-2 rounded bg-white">
                        <h3>Box Pushing Games Similar to Sokoban</h3>
                        <p>
                            Some games are similar to Sokoban in the sense that they require players to push boxes or
                            block elements, but they have different objectives or win conditions.
                        </p>
                        <p>
                        To get an idea of what each one looked like, please see the
                            screenshots below:
                        </p>
                    </div>

                    <div className="row mb-2">
                        {variantsPushBoxesCollectItems.map((game, index) => (
                            <div key={index} className="col-12 col-sm-12 col-lg-8 mb-2">
                                <GameCard game={game}/>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-12 rounded bg-white">
                        <h3>What does the word 'Sokoban' mean?</h3>
                        <p>The word Sokoban is written using Kanji.</p>
                        <p>
                            Ming Wu, a Chinese fan of the game, kindly explained to me how it
                            was written:
                        </p>
                        <ul>
                            <li>倉庫番 (Traditional Chinese characters).</li>
                            <li>仓库番 (Simplified Chinese characters).</li>
                            <li>
                                倉庫番 == 仓库番 are exactly the same in meaning and in saying.
                            </li>
                            <li>倉庫番 in Japanese means "warehouse keeper".</li>
                            <li>
                                倉庫番 in Chinese means "spending time in the warehouse" or
                                "things happening in the warehouse".
                            </li>
                            <li>Most people in China call the game 推箱子 (Push box).</li>
                        </ul>
                        <p>Katakana: ソウコバン. Hiragana: そうこばん.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 rounded bg-white">
                        <h3>Thanks</h3>
                        <p>I'm really grateful to Mr Takatoshi, who helped me with:</p>
                        <ul>
                            <li>
                                Transcription of the type-in programs: Sokoban Tiny Editor
                                (MZ-700, PC-8001), Sokoban for Pasopia 7.
                            </li>
                            <li>
                                Dump of the cassette tapes: Sokoban 2 (PC-6001 mk2, PC-6001
                                32k).
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </section>
    );
}
